import React from 'react';
import Demo from '../components/Demo';
import Layout from '../components/layout';
import { ErrorBox } from 'lib/components';
import { graphql, StaticQueryDocument } from 'gatsby';

const query: StaticQueryDocument = graphql`
    query locales404 {
        locales: allLocale(filter: { ns: { in: ["general", "policy-library"] } }) {
            edges {
                node {
                    language
                    data
                    ns
                }
            }
        }
    }
`;

function Page404(): React.ReactElement {
    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Layout>
            <Demo />
            <ErrorBox
                code={404}
                header='Page Not Found'
                message='Sorry, but the page you were trying to view does not exist. It looks like this was the result of either:'
                issues={['A mistyped address', 'An out-of-date link']}
            />
        </Layout>
    );
}

export { Page404 as default, query };
