import React from 'react';
import { Button, Link, Well, WellHeader, WellContent } from 'lib/components';
import { useTranslation } from 'gatsby-plugin-react-i18next';

/**
 * Renders a well and a link to demo policy manager.
 */
export default function Demo(): React.ReactElement {
    const { t } = useTranslation();

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Well>
            <WellHeader header={t('demo.header')} subheader={t('demo.subheader')} variant='standard' />
            <WellContent>
                <Link to='https://www.mcnhealthcare.com/policy-management-software-demo/' target='_blank'>
                    <Button buttonText={t('demo.buttonText')} className='btn btn-secondary' />
                </Link>
            </WellContent>
        </Well>
    );
}
